import {
  type Class,
  type Shindig,
} from '../__generated-gql-types__/globalTypes';

export const getCategoryForEvent = (
  itemCategorySlugs: string[],
  eventCategorySlugs: string[],
) => {
  const eventCategory = itemCategorySlugs.find((category) =>
    eventCategorySlugs.includes(category),
  );
  return eventCategory ?? eventCategorySlugs[0];
};

export const isEventShindig = <
  S extends Pick<Shindig, '__typename'>,
  C extends Pick<Class, '__typename'>,
>(
  event: S | C | undefined,
): event is S => {
  return event?.__typename === 'Shindig';
};
