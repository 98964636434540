import type { ReactElement } from 'react';

export interface SelectOptionType<Value> {
  label: string;
  value: Value;
  disabled?: boolean;
}

interface CreateableProps<Value> {
  onCreate: (value: string) => Promise<Value>;
  itemType: string;
}

type SearchableProps<Value> =
  | {
      isSearchable: true;
      /** Prevent default search for filtering `options` based on input
       */
      preventFilterSearch?: boolean;
      onInputChange?: (input: string) => void;
      createProps?: CreateableProps<Value>;
    }
  | {
      isSearchable?: false;
      preventFilterSearch?: never;
      onInputChange?: never;
      createProps?: never;
    };

export type CommonSelectProps<Value> = SearchableProps<Value> & {
  className?: string;
  label: string;
  labelClassName?: string;
  labelIsHidden?: boolean;
  subLabel?: string;
  flag?: string;
  placeholder?: string;
  options: Array<SelectOptionType<Value>>;
  loading?: boolean;
  renderOption?: (value: Value) => ReactElement;
  error?: string;
  required?: boolean;
  customErrorMessage?: string;
  disabled?: boolean;
};

export const DEFAULT_NO_OPTIONS_LABEL = 'No options';

export const createableLabel = (input: string, itemType: string): string => {
  return `Create ${itemType} "${input}"`;
};
