export const addUtmParams = (
  link: string,
  {
    utmMedium,
    utmCampaign,
  }: {
    utmMedium?: string;
    utmCampaign?: string;
  },
) => {
  const url = new URL(link);
  url.searchParams.set('utm_source', 'site');
  if (utmMedium) url.searchParams.set('utm_medium', utmMedium);
  if (utmCampaign) url.searchParams.set('utm_campaign', utmCampaign);
  return url.toString();
};

export const addUtmParamsFromArticle = (
  link: string,
  articleSlug: Possible<string>,
) =>
  addUtmParams(link, {
    utmMedium: 'article',
    utmCampaign: articleSlug ?? undefined,
  });
